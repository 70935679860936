var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"body"},[_c('h2',{staticClass:"title"},[_vm._v("Articles")]),_c('h3',{attrs:{"id":"ajout"}},[_vm._v("Ajouter un article")]),_c('h4',[_vm._v(" Si vous souhaitez ajouter un nouvel article sur notre site web "),_c('router-link',{attrs:{"to":"app.batigo.fr"}},[_vm._v("app.batigo.fr")]),_vm._v(", veuillez suivre les étapes ci-dessous : ")],1),_vm._m(0),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499388_AjouterUnArticle.mp4',"controls":""}})]),_c('h3',{attrs:{"id":"edit"}},[_vm._v("Modifier un article")]),_c('h4',[_vm._v(" Si vous souhaitez modifier un article sur notre site web "),_c('router-link',{attrs:{"to":"app.batigo.fr"}},[_vm._v("app.batigo.fr")]),_vm._v(", veuillez suivre les étapes ci-dessous : ")],1),_vm._m(1),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682499407_ModifierUnArticle.mp4',"controls":""}})]),_c('h3',{attrs:{"id":"delete"}},[_vm._v("Supprimer un article")]),_c('h4',[_vm._v(" Si vous souhaitez supprimer un article, veuillez suivre les étapes ci-dessous : ")]),_vm._m(2),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/11682596642_Suppression  un article.mp4',"controls":""}})]),_c('h3',{attrs:{"id":"recherche"}},[_vm._v("Rechercher ou filtrer les articles")]),_c('h4',[_vm._v("Recherche d'articles :")]),_vm._m(3),_c('h4',[_vm._v("Filtrage des articles :")]),_vm._m(4),_c('div',{ref:"video",staticClass:"video"},[_c('video',{attrs:{"preload":"metadata","src":_vm.downloadUrl +
          'public/how-it-works/videos/1682596346_Recherche et filtrage article.mp4',"controls":""}})]),_c('div',{staticClass:"bulle"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"33","height":"33","viewBox":"0 0 33 33"}},[_c('g',{attrs:{"id":"Icon_feather-info","data-name":"Icon feather-info","transform":"translate(-1.5 -1.5)"}},[_c('path',{attrs:{"id":"Tracé_78064","data-name":"Tracé 78064","d":"M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78065","data-name":"Tracé 78065","d":"M18,24V18","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}}),_c('path',{attrs:{"id":"Tracé_78066","data-name":"Tracé 78066","d":"M18,12h0","fill":"none","stroke":"#4d569b","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3"}})])]),_vm._v(" Nous espérons que ces instructions vous ont été utiles. Si vous avez des questions ou des problèmes avec un article, n'hésitez pas à nous contacter via notre email de contact. Merci de nous faire confiance et à bientôt sur Batigo ! ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v(" Allez sur la page \"Articles\" en cliquant sur le bouton correspondant dans le menu principal. ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Nouveau\".")]),_c('li',[_vm._v(" Remplissez le formulaire d'ajout de l'article (* il y a des champs obligatoires). ")]),_c('li',[_vm._v("Téléchargez la photo associés à l'article si nécessaire.")]),_c('li',[_vm._v("Cliquez sur le bouton \"Enregistrer\" pour publier l'article.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page de gestion des articles.")]),_c('li',[_vm._v(" Recherchez l'article que vous souhaitez modifier en utilisant les filtres de recherche ou en parcourant la liste des articles. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Modifier\" à côté de l'article que vous souhaitez modifier. ")]),_c('li',[_vm._v(" Modifiez les informations de l'article dans le formulaire de modification. ")]),_c('li',[_vm._v(" Cliquez sur le bouton \"Enregistrer\" pour enregistrer les modifications. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Accédez à la page de gestion des articles.")]),_c('li',[_vm._v(" Trouvez l'article que vous souhaitez supprimer et cliquez sur le bouton \"Supprimer\". ")]),_c('li',[_vm._v(" Une fenêtre de confirmation apparaîtra pour vous demander de confirmer la suppression. Cliquez sur \"Oui\" pour continuer. ")]),_c('li',[_vm._v("L'article sera supprimé de manière permanente de votre compte.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Cliquez sur la page \"Gestion des articles\"")]),_c('li',[_vm._v(" Dans la barre de recherche, tapez le nom de l'article que vous recherchez. ")]),_c('li',[_vm._v("Cliquez sur le bouton \"Rechercher\".")]),_c('li',[_vm._v(" Les articles correspondant à votre recherche s'afficheront sur la page. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ol',[_c('li',[_vm._v("Cliquez sur la page \"Gestion des articles\"")]),_c('li',[_vm._v("Cliquez sur le bouton \"Filtrer\".")]),_c('li',[_vm._v(" Sélectionnez l'option \"Afficher les articles publics\" ou \"Masquer les articles publics\". ")]),_c('li',[_vm._v(" Les articles correspondant à votre choix s'afficheront sur la page. ")])])
}]

export { render, staticRenderFns }