<template>
  <div class="content">
    <div class="body">
      <h2 class="title">Articles</h2>
      <h3 id="ajout">Ajouter un article</h3>
      <h4>
        Si vous souhaitez ajouter un nouvel article sur notre site web
        <router-link to="app.batigo.fr">app.batigo.fr</router-link>, veuillez
        suivre les étapes ci-dessous :
      </h4>

      <ol>
        <li>
          Allez sur la page "Articles" en cliquant sur le bouton correspondant
          dans le menu principal.
        </li>
        <li>Cliquez sur le bouton "Nouveau".</li>
        <li>
          Remplissez le formulaire d'ajout de l'article (* il y a des champs
          obligatoires).
        </li>
        <li>Téléchargez la photo associés à l'article si nécessaire.</li>
        <li>Cliquez sur le bouton "Enregistrer" pour publier l'article.</li>
      </ol>
      <div ref="video" class="video">
        <video
          preload="metadata"
          :src="
            downloadUrl +
            'public/how-it-works/videos/1682499388_AjouterUnArticle.mp4'
          "
          controls
        ></video>
      </div>

      <h3 id="edit">Modifier un article</h3>
      <h4>
        Si vous souhaitez modifier un article sur notre site web
        <router-link to="app.batigo.fr">app.batigo.fr</router-link>, veuillez
        suivre les étapes ci-dessous :
      </h4>

      <ol>
        <li>Accédez à la page de gestion des articles.</li>
        <li>
          Recherchez l'article que vous souhaitez modifier en utilisant les
          filtres de recherche ou en parcourant la liste des articles.
        </li>
        <li>
          Cliquez sur le bouton "Modifier" à côté de l'article que vous
          souhaitez modifier.
        </li>
        <li>
          Modifiez les informations de l'article dans le formulaire de
          modification.
        </li>
        <li>
          Cliquez sur le bouton "Enregistrer" pour enregistrer les
          modifications.
        </li>
      </ol>
      <div ref="video" class="video">
        <video
          preload="metadata"
          :src="
            downloadUrl +
            'public/how-it-works/videos/1682499407_ModifierUnArticle.mp4'
          "
          controls
        ></video>
      </div>

      <h3 id="delete">Supprimer un article</h3>
      <h4>
        Si vous souhaitez supprimer un article, veuillez suivre les étapes
        ci-dessous :
      </h4>

      <ol>
        <li>Accédez à la page de gestion des articles.</li>
        <li>
          Trouvez l'article que vous souhaitez supprimer et cliquez sur le
          bouton "Supprimer".
        </li>
        <li>
          Une fenêtre de confirmation apparaîtra pour vous demander de confirmer
          la suppression. Cliquez sur "Oui" pour continuer.
        </li>
        <li>L'article sera supprimé de manière permanente de votre compte.</li>
      </ol>

      <div ref="video" class="video">
        <video
          preload="metadata"
          :src="
            downloadUrl +
            'public/how-it-works/videos/11682596642_Suppression  un article.mp4'
          "
          controls
        ></video>
      </div>

      <h3 id="recherche">Rechercher ou filtrer les articles</h3>
      <h4>Recherche d'articles :</h4>

      <ol>
        <li>Cliquez sur la page "Gestion des articles"</li>
        <li>
          Dans la barre de recherche, tapez le nom de l'article que vous
          recherchez.
        </li>
        <li>Cliquez sur le bouton "Rechercher".</li>
        <li>
          Les articles correspondant à votre recherche s'afficheront sur la
          page.
        </li>
      </ol>

      <h4>Filtrage des articles :</h4>

      <ol>
        <li>Cliquez sur la page "Gestion des articles"</li>
        <li>Cliquez sur le bouton "Filtrer".</li>
        <li>
          Sélectionnez l'option "Afficher les articles publics" ou "Masquer les
          articles publics".
        </li>
        <li>
          Les articles correspondant à votre choix s'afficheront sur la page.
        </li>
      </ol>

      <div ref="video" class="video">
        <video
          preload="metadata"
          :src="
            downloadUrl +
            'public/how-it-works/videos/1682596346_Recherche et filtrage article.mp4'
          "
          controls
        ></video>
      </div>

      <div class="bulle">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="33"
          height="33"
          viewBox="0 0 33 33"
        >
          <g
            id="Icon_feather-info"
            data-name="Icon feather-info"
            transform="translate(-1.5 -1.5)"
          >
            <path
              id="Tracé_78064"
              data-name="Tracé 78064"
              d="M33,18A15,15,0,1,1,18,3,15,15,0,0,1,33,18Z"
              fill="none"
              stroke="#4d569b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              id="Tracé_78065"
              data-name="Tracé 78065"
              d="M18,24V18"
              fill="none"
              stroke="#4d569b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
            <path
              id="Tracé_78066"
              data-name="Tracé 78066"
              d="M18,12h0"
              fill="none"
              stroke="#4d569b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3"
            />
          </g>
        </svg>

        Nous espérons que ces instructions vous ont été utiles. Si vous avez des
        questions ou des problèmes avec un article, n'hésitez pas à nous
        contacter via notre email de contact. Merci de nous faire confiance et à
        bientôt sur Batigo !
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";

export default {
  data() {
    return {
      downloadUrl: domains.download,
    };
  },

  computed: {
    ...mapGetters([""]),
  },

  methods: {
    ...mapActions([]),

    playPause() {
      var parent = this.$refs["video"];
      var myVideo = parent.children[0];

      if (myVideo.paused) {
        parent.className = "hideVideo";
        myVideo.play();
        myVideo.controls = true;
      }
    },
  },

  watch: {
    "$route.hash": {
      handler(newHash) {
        if (newHash) {
          this.$nextTick(() => {
            const video = document.getElementById(newHash.slice(1));
            if (video) {
              window.scrollTo({
                top: video.offsetTop,
                behavior: "smooth",
              });
            }
          });
        }
      },
      immediate: true,
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.content {
  flex: 1;

  .body {
    @media only screen and (max-width: 1024px) {
      width: 97%;
      padding: 15px;
    }
    @media only screen and (max-width: 724px) {
      width: 100%;
    }
    width: 95%;
    margin: auto;
    padding: 24px 32px 48px;
  }

  .title {
    color: #242424;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
  }

  h4 {
    font-size: 18px;
    font-weight: 500;
    color: #474747;
    margin-bottom: 18px;
    line-height: 1.5;
  }

  h3 {
    color: #474747;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  p,
  ol {
    font-size: 12px;
    color: #474747;
    font-weight: 300;
    line-height: 2;
  }

  .bulle {
    @media only screen and (max-width: 900px) {
      padding: 16px;
      font-size: 12px;
    }
    border-radius: 25px;
    margin-bottom: 24px;
    border: 1px solid #dee2ff;
    background-color: #dee2ff;
    padding: 24px;
    display: flex;
    gap: 12px;
    align-items: center;
    color: #4d569b;
    font-size: 14px;
    line-height: 1.5;

    svg {
      @media only screen and (max-width: 900px) {
        display: none;
      }
      width: 48px;
      height: 48px;
    }
  }

  .double-img {
    @media only screen and (max-width: 900px) {
      display: block;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    img {
      @media only screen and (max-width: 900px) {
        width: 100%;
        margin-bottom: 12px;
      }
      width: 48%;
    }
  }

  video {
    width: 100%;
    height: auto;
  }
  .video {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    position: relative;
    width: 80%;
    margin: 0 auto 24px;

    .play {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($color: #000, $alpha: 0.4);
      display: flex;
      align-items: center;
      justify-content: center;

      .circle {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #373737;
        width: 84px;
        height: 84px;
        border-radius: 50%;
      }
    }
  }
}

.showVideo {
  .play {
    display: flex;
  }

  .pause {
    display: none !important;
  }
}

.hideVideo {
  position: relative;
  width: 80%;
  margin: 0 auto 24px;
  .play {
    display: none;
  }

  &:hover .pause {
    display: flex;
  }
}
</style>
